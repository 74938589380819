import "../core/src/components/page-list/page-group.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/page-group.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VU227bMAx9z1cIAQY0wBQ4SZslHgr0P4ZhkCXa4SJLhiRnyYb8+2DJqe8p2j75QuqI5/CQL0e4pIblYMmvTSme9scV+TcjJPriH4TognF0l5hE3/33ATA7uJhExbn6cZ0RsoqG2atu9omZB0oNE3imXEvJCouJBMq1cqAcDXmLgHidvQyqWk9U9Zl7xiuf4nmdLetaIn9EoC0ku8QkleBTqicVaIA71ComXMsyV1UkY0VMthVQA7LxIPoEJpX6T0wOKASoTsYPwRyj1jEHz3NdgJr/9IeYwpyFO157Fi03lgCzQFFRXbppIC61BTEJtb4L9fgh6o9d6k/jIExipig6yG1MOCgHpvpdMCFQZb4TZLUtzv5lG9py69J6F74Txo+Z0aUSNy+wNEUFtAnQwmDOzKXyhzbeCqUFQy1I4C4mSivoVBsfqi75mifhfUpApClKCWLRxtj60xVR79gm8M0HeGmsNjEpNAbWTcIunNTKUYt/oXdt+J97Ev7yXtzB2VELXCvRZtzA7z8PL9CyMGJ9cDbe5+6kTXT9d2kdppfb4LZDA7XeIHC2i45V6olOtBFg/K4obe3RttvIruta9k4fLMZM1JWmvcWa3KQbi3oEb/WPNQS5Vi1fO8OUxTCS/j3VJifRcj0530lYFfXqBPE8T5m0UG+LV4yYGF1tk4d9JCC7yzT5SqbBnSlv2ONa8Hetijum6XV7HTScFqilMKoDGOzIxO8xGapEhzLVCPXwTEgRLTfek8HoFE6gnB1uKP4BZ/4HPzxSW/gHAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = '_3ud59kb';
export var collapsedIconContainer = '_3ud59kc';
export var collapsibleContent = '_3ud59k3';
export var collapsibleContentInner = '_3ud59k4';
export var header = '_3ud59k5';
export var headerCollapseIcon = '_3ud59k7';
export var headerCount = '_3ud59k9';
export var headerLabel = '_3ud59k8';
export var root = '_3ud59k0';
export var selectAllButton = '_3ud59ka';
export var spacer = '_3ud59k6';