import "../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81UTW/bMAy951cQBQYkBwV2mnadetk/GWiLTtTKkiAxibsh/32QnMwfbTZsp53iUI/ie48U19/KetO9bGIBPxYAe9K7PUsoi+LT8wLgpBXvh79KR2/wTUJjqEuB9CuUDlSzdlZC7cyhtfnEWRZRfycJRwxLIbBptCWR4xVGWiWUd1H3mYEMsj5SilZYv+6CO1g1Sx4OhA+6xfAmamdcWD0vzov1VUqZpeQDCdruKWieADYZwM5LKFI9Qw1fPkOvv/hQ7cC20R2pFEKjd1ZopjZKqMkyhRR+OUTWTWJnmSxLiB5rEhXxiSj741EpbXcSykffQbkpfDfheJ85vqPQGyx26CVsZynbse6Jb0wdv3PsDz2K7YA5XcbiocjG5OsU1S5gb4d1liaitknT04zfQ+Y3KrrJCpKvOhkn6EiWowQ8sJtmrr1Bbf9O3yj/KWcOw3w8PU+H/bif0C/+dQoBKhcUBRFQ6UO83pRjg02V60Tco3Kna2zE9cv/08Vy4zvoJxOgxU7M1sGN1/uBAxzQXrGDfVCs7yMQxpkDEhumcPHh8oDu7qYVsYrOHPhSkdm181dbGVe/jpZYjaZeJuogYFv4bjWegWL90Mu83XWD9asoi9WMq3W8lAYjC9cIfvO0ysRbDDttxYjaefG1JaURYh2ILKBVsJy4mt5Dnz1bVfN1ce3JeQy9v0B/GXDt5wT1+TbqY4La/pbg4637bjXrvDj/BBe34L51BgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = '_1c2xj2s1';
export var hideInSmallScreen = '_1c2xj2s7';
export var hideInWideScreen = '_1c2xj2s6';
export var iconButton = '_1c2xj2s5';
export var menu = '_1c2xj2s8';
export var menuItem = '_1c2xj2s9';
export var root = '_1c2xj2s0';
export var topNav = '_1c2xj2s2';
export var topNavLink = '_1c2xj2s4';
export var topNavLinks = '_1c2xj2s3';