import "../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VV226jMBB9z1eMui/bB0ckIaTraL9ktVoZPBC3xka+NOmu+u8rm9ACISl5QvLczpwzMyz/rNKDzHKZwL8FQM1MJRRxuqGwSZrTfvG+WHYuq54LBead3n+G5No5XVNIQxCAw5MjTIpKUShQOTSDVOsr1QAKLbWh8MrMd0JYWQqFJCazWGjFmXkj0eUxOJdaOWLFXxwFxPeTjT4yPBxQVAdHYbXcjnCwiGSqqhTq5aPWIGYZTG2cNzYENlq0Pd6XahOTNNoKJ3TgNLdaeochj2kRJ/sRUWSdjXRJ+2R2Olyot53XKEDOipfKaK84BWeYsg0zqFw0acPRkHOSkfFuMdbrVnJv0RCLEgtHQWmFA9xZxH0zua0f7x6dr0nvmEwGcHYRTtfCbte2wIVtJHujUEqMD+FLuDBYtEUKLX2tguXZWyfKAEU5VO5zPQDivhDhsLb955tL+RTxXNS/UaViDYWnFvekHl9z3av/4+pYReobI+qJnT2eS2bJkN12G4+CuwOFdDvrluSDmOR6zLwpOrMtsQz3Irt+lXLp8bKjdNRREdFNw7lHtSuzMWfpBvi2ycVFyc48jy/5eNT49KjNgfAxYklvxEaQZt7Sxhskx4NwOLpWZFIkwzpLu/aMc6EqCkn3pzrfNMO48Pbj/3WvEn2afnHmGBGW5CgFvqL5+eCMx4ffkb9LwN82u3S1Xe0X7/8BkCqy644HAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_14hl6blc';
export var authMessage = '_14hl6bl2';
export var authModalContent = '_14hl6bl0';
export var captchaWrapper = '_14hl6bl1';
export var forgetPasswordButton = '_14hl6bl6';
export var forgetPasswordButtonRow = '_14hl6bl3';
export var linkButton = '_14hl6bl5';
export var resendCountdown = '_14hl6bla';
export var resendCountdownInButton = '_14hl6blb';
export var resendWrapper = '_14hl6bl7';
export var sendMagicLinkButtonRow = '_14hl6bl4';
export var sentMessage = '_14hl6bl9';
export var sentRow = '_14hl6bl8';
export var userPlanButton = '_14hl6bld';