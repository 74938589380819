import "../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51V246bMBB9z1fMS6VNt44gt+462i+pVpVjD+AWbMs2CWnVf68wEJOQrJo+EOLx+MyZM+Nh8X3ziutlknwTzDOClfGnN29rfIffMwCuS20pHJh9IoRlmVRITMk4FroUaEnYn+9mf2aLHigN54R0pmQnClmJzW4G4U2EtMi91Iq2wHWl2p2jFL6gkCbJp3aZM0MhXZpmDLq8DRp8tyYGONrW0v5OkCPYKoAZJoRUOYWkdfWWKZdpW9Hub8k8PpHVYmOaL8BZyfsFPPdaWCZkQ4w2Bi1hihfakgJlXvg5fAaSzucjBgHg/rngNYdnSLemCef0AW1W6iOFQgqBakx//YEW67tanNNNE9P0+gLsGf+ZW10rQW5Vumh5nGsMUEnVJ0lhfVWiTaAVIue2ZZ5OwwapK2ZzqSiQaNprK9BSUFphSL/2pVQYDZlWnjj5CymkQ46tKWOVLE8UpCrQSr+LHTuyTJMMJTbMovIXKVA66u3/7P/to/0fCvfSJRWbsrUMT6daE6VPks4Y24TVXo9ZfL3N4kftvMxOhGvlUXkKzjCOZI/+iBjYsFLmikiPlaPAUXm0U2JXJblQJ9gbNz/7HHvWmyTZ3VHUY+OJQ66VYPZ0Q9SXkM6QLmnivQAw2slOU4sl8/KA13otB72i1BH69d/qZfXxIXmG2Kv1dWgAXlvXamC0HAC6GxCmQ+36ezzi2A3nTPPaoRiN54fub8RjnZyGcelPYQKOYtFwCu74phc4HS/nmcc3bVC9f+C8vzEg4i4Pu/28XCaXIg7rSZXulGPS5sNWjCfG8dLtZbxhfVWVTfiO/AWYK54pMgcAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_59e4202';
export var searchInput = '_59e4205';
export var spacer = '_59e420b';
export var tagColorIcon = '_59e420d';
export var tagColorIconWrapper = '_59e420c';
export var tagEditIcon = '_59e420a';
export var tagSelectorItem = '_59e4209';
export var tagSelectorTagsScrollContainer = '_59e4208';
export var tagsEditorRoot = '_59e4201';
export var tagsEditorSelectedTags = '_59e4204';
export var tagsEditorTagsSelector = '_59e4206';
export var tagsEditorTagsSelectorHeader = '_59e4207';
export var tagsInlineEditor = '_59e4200';
export var tagsMenu = '_59e4203';