import "../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVwW7bMAy95yt0KdAAVeA46ZYq2JcMOygWHbNVJE+im2TF/n2QZDu2m7YIdgkc0iQfH/noxfPvNYJfsrcZY7X1SGiNYA60JHyF7YyxIyqqBFtm2V34WwHuK7r8P6DhnW2V16ft7O9skbLmMatCX2t5FqzUcAoRUuPecCQ4eMEKMARuELSKQfYVXKntkZ8Eq1ApMNsPAU4R7WUt2GaEZB2TBgD86II7/G47k0IHRcpbWN0czDuU8TVP0lFfYD0q8Hi91ZSOXxB90P2wNbnzVjd0tbWDPPHJPMhJ04dqzbJF7lm2WHkG0gNHw21DA6RLUQVy2Qj4IO+rdPecJ2c2HwR+m+zIECfZWrAsPGkoqX10CXt2rRFbywLp3Hqv9RDsO1m87J1tjBJMowHp+N5JhWDo/ilTsH9IobV0YIhldw8tfFmWaIDHTnlhtXW8RK1Bzdk6u5t/TEdqske3HLz5Pbq6RvI8jfPdyIMeWiqzT+b93HjC8swLawgMCeZrWQDfAR0hLPulrNDSEy8q1CrN6lIgjK1NMIjY3CC7r4EwVkul0OwFy7odjoyKMdcEJ+K1w4N050T5PI7QOtXNYBIxdA1HsvmpJEle2qLxoH6Qa+BXbOmTXNPCfbKnpHxriHv8A4KhqcAhDbCFjWp82M36nWjXydS+2UlvZPR01iCYtxrVdGnH/faOKzwNlZ23NE9FEynuLqNgoDXWHqNSjhUS8Dg6wYxN560nQU5JGOFK9sN8NOt1fWLL/D0hLbRboVyaWWWjw7n7GlrFH+fTe3I5Q56weDmPpIhGx+C4+H2lIlZqOZ4wvBlNtFuIx0R7Ov6VQ/MSq/cZ1ehTNfxQ3U5P48MqgYaCgtnAoA78n6Avrn7BJvuV39I+Y0XjfFBgbXHy8Yb2mr59poPBVQ5K/Qe26VgDgwgAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hoverMaxWidth = 'var(--jq4ies0)';
export var innerBackdrop = 'jq4ies6';
export var innerContainer = 'jq4ies5';
export var root = 'jq4ies1';
export var showMoreTag = 'jq4iesb';
export var tag = 'jq4ies7';
export var tagIndicator = 'jq4iesc';
export var tagInline = 'jq4ies9 jq4ies8';
export var tagInnerWrapper = 'jq4ies8';
export var tagLabel = 'jq4iesd';
export var tagListItem = 'jq4iesa jq4ies7';
export var tagRemove = 'jq4iese';
export var tagsContainer = 'jq4ies2';
export var tagsListContainer = 'jq4ies4 jq4ies2';
export var tagsScrollContainer = 'jq4ies3 jq4ies2';