import "../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61V247aMBB95yumD5V2pRolAQJ1X/orTjwEt4kd2c6S3Yp/r+w4NyDstlqBIJ6Mx2fmzBmvTZTmL4cI/qwATiiKk6UQR9HXHysALkxdslcKxxJbZ2ClKCQRFitDIUdpUTvzr8ZYcXwluZIWpR1fXVbrLn7s49fKCCuUpMAyo8rGotstpEFLIXLPZ8HtaQQwBzRESx5Eu8Hs/gkXGvPOOVdlU8mHsBcT7fFs0rqdwO2XmdIcNdGMi8Y41J3ZqppCtN5prNxSdzFGQ8by34VWjeQUXph+IoQdj0IiOZ+ExecxMgWppM+yZpwLWUyj5I02SlOolejh5qp0lllMkStJ/Asf2Gom+zKOOCBaJwaQGSRCEtVY5/pGhOTYUojdqmK6EJL43DaHLtFgCwl21oG0jSftHqQxzfuUHpW0xIg3pJCGkpZuX89Gb/R+52DMVMmdUdUsF/bVVSqZVw7iXbfvpnID5q3HfNNS1zoZKpPcbStTsxxJhvaMKBe7azh0d//Q5T5eaNYpX0Pw9JOCNwY1MVhibvuuHM7Yf4Dpafsk21mjHGazqNdWxVoS5OYeQ3GvGA2+N+n9u9ILVlM43JX1dmr1CqxbMKoUfJ5s2DZqbdQXeVycTLXEnBhXZwpR3bq0IHY/bqGLjD1F3yB81/F23CPefCFCmiRT7QfHwCM6v99vmf+fnkmoYCVkT+kmmAYu47qFZCLrTv7xvFOYRxZCeB7uqXNxvN4Q9Hn3XTbr4eRBdpMi7KKFhN+lEL6IqlbaMqeIEQQ9qRfUHso7vecdh0oMEfJPmhbjXD+KFnnXr9aqikISRDadocP53J8/kX56SJcLMhHQhPKgF/9Z73fPcyKi2c01gIpnfYYexniTXF+c4Q1c35mX1c8KuWBgco0ogUkOT5Ns9umhbp998NnsX6zjZfTcB0+LrSXefe54+QtayFhe2gkAAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var captionStyle = 's06cv8d';
export var cursorStyle = 's06cv89';
export var dividerStyle = 's06cv8a';
export var imageBottomContainerStyle = 's06cv8c';
export var imageNavigationControlStyle = 's06cv84';
export var imagePreviewActionBarStyle = 's06cv88';
export var imagePreviewModalCaptionStyle = 's06cv87';
export var imagePreviewModalCenterStyle = 's06cv86';
export var imagePreviewModalCloseButtonStyle = 's06cv82';
export var imagePreviewModalContainerStyle = 's06cv85';
export var imagePreviewModalGoStyle = 's06cv83';
export var imagePreviewModalStyle = 's06cv80';
export var imagePreviewTrap = 's06cv81';
export var scaleIndicatorButtonStyle = 's06cv8b';
export var suspenseFallbackStyle = 's06cv8e';