import "../component/src/components/auth-components/onboarding-page.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/components/auth-components/onboarding-page.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W247aMBB95yuslSotD145XLI0q0p97TdUVeXEk8SLY0e2IdBq/72yc8EJsCxtXwCNx+eMz9x4+hntix1Z5QT9niGU0mxbaLWTDGdKKJ2gPdWPGNM85xJwcFxrXlF9bN3mLzOESuBFaRMUEbIvnYFxUwt6TFAu4OAM7hszriGzXMkEZUrsKulO1B50LlSToJIzBvJl9jZ7GkL7zqilmBtc0UwZDAIyq5X88mD1Dh5++MgrqgsuE7SpPVWqNAONNWV8ZwZrH2JGRfbo40QYRXF9mI8II49YU8a4LLBVdYIWqxahN+oWaDArw9s3Gcuz7dHZ/D1ypxBU8EJibqEyrTsGydwBbiDdcotpXWMNhb/GNC3GgQ9KNVwy1VzRavQycuFZ3lbQOvHijCgWIUKCFuS6AKmyVlUd2t9XVkC+9OR3yPm6M5bnDktakC7zIC3oM6VP5uFlG1If0IK4zygmExVWXc0dcMOZLRMUk6nL+p9jNTXNAKdgGwA5ZGQZt4pXXOK+nlfrKXvs2XMlLc5pxcUxQd/6J3qr4b9gkgNvL3E0PznZo4AESaUrKgZr07HGxGdWuLt9JMtpvTzfK8N5BxhLtZ1UZIdQaDczohHj5mOMWjUfoes7vD0qNZdbX9EB4WdPeKWeArFbqNFFsy/85YstoWnfLPNLEfQzr2/azQSdnkrgWrJTamB+ltc1Gb8v9UBdoa8X01LLTjyTyvgIdwDEAh0NiHycj8AR2hk0jByaGiV2FkazVioJ7WTiLhcY9iCt6e0BWh7sj/FcD5yKe8s4KJ4AprydE8utuJCU5wvNtprC89vwJY5H23pJugl+s7UX09Z+vaLJe/QHM/6v0DWzV6v73RVaRMini2Mx3Ir/Zatsr7aghYPFBjIl2bW7yZ4bboHdj/G1AsYpMpkGkIhKhh6DhRIRVzxzDzvZf8GSctvJjVy/rZwgb6H7auq+cv4+s6Pl1Uv9djOq53hzFlTasZyD/QFIn82rXQoAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var button = '_1vgu04fc';
export var buttonWrapper = '_1vgu04f8';
export var checkBox = '_1vgu04f9';
export var content = '_1vgu04f5';
export var disableButton = '_1vgu04fe';
export var footer = '_1vgu04f2';
export var header = '_1vgu04f1';
export var input = '_1vgu04fb';
export var label = '_1vgu04fa';
export var layout = '_1vgu04f0';
export var link = '_1vgu04fk';
export var linkGroup = '_1vgu04fj';
export var onboardingContainer = '_1vgu04f4';
export var openAFFiNEButton = '_1vgu04fd';
export var optionsWrapper = '_1vgu04f7';
export var question = '_1vgu04f6';
export var scrollableContainer = '_1vgu04f3';
export var thankContainer = '_1vgu04fg';
export var thankText = '_1vgu04fi';
export var thankTitle = '_1vgu04fh';
export var windowsAppButton = '_1vgu04ff';