import "../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61XXW+jOhB9z6+w9mql9sEISNJ2Xelq/8nVgIfEjbGRbZJ0V/3vK4xJgEJCV/cpwczY55z5Mj8P+F4YKNGS/5Ln8+HtZNbk94qQ+Lv/IcQZULbQpmTEaAcOH2KOu8fXFSEfK0K2NwyTl4FpEt+wXT/1bD9WUYcmJpHUO+39Cq0cteIXMnIE80ApFIVQSP36nq69t384odjtHSNPcdys5VpqM3LKJOQH71GC2QlFTXCpzs3iEY0TOUgKUuwUI6XgXOLrENq/pGKFMNZRXVD3XuESmNtZmAFIpp3TJSObFolsfPfBMn1pF7mwlYR3RgqJfsHDpMJhaRnJUTk0E2AlfA3rZhbrBKreaYnfvALOhdpdCK3jFnylrXBCK0YMSnDiONQ1iU7C7XXt6F4oN7lTGn86UEKG0htftMmkzg9z8Xd4dtRirhUH8069yeN8FG4KZcuFOqUj2On9IIS9r5pBZrWsHTar41TBwjGy5NwIjdHGnz4ljn970WTgeAKjhNrNuob3U84b73QS3O0ZSbYt5g5j9zyVHA1Rw9FQA1zUljVdp1nVRzSF1CdG9oJzVN4S8sPO6FpxOln27UZT6BiDwmEninKoHCPfvr32MKcjzOkYcz84vWiE3uL/O12Ff130esXPCNROT/P4pyiK5s0vKhTHMyPpnDJDVhkW2uANWmk8pNU935NS1ngjN1v6IVId1fB4oZB4RbppQLURXoTGN/j45qZECe0J1ymVRFtLhCqEEg59vsOw5W3vF1cGtqUwKJf54RJ6gw/hetyDtgRmq0IKdZhKuqfJ9rZZ1iifh+2uGwVvtXWiaFpaCLatIEeaoTthWyP3hsXLwsawrHX5ThvGaHfYSPEk2n65ji4p1Wo1rJ8emR+eTHfSOp0ZoJ9Uu0KdlOtOMvwgkUGLvnQ4XifZ1IxYIuLwkjKetzDgmMTxcd9vW3F8PP1PpJeU0wBYdDJQVaGtfvnaAiQK2Lx/CWcaSD3HY8mBRE44ueBi482WXsM+XW6ARLbOrkfdt/96X8juBfT7ZDybX8qFwbwto1zLulR/E+kelrw/ttddpv8skQsgNjeIioDi5KEXnSRON9X50XuOsvQWyksr9Byr82UUTRDwu1gHxnUfGHNZd+vEgR8P5hddlVbYfZLcJrxtkI745mG7UdQ+Vh9/AFk1Cll3DQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authCodeContainer = '_17xkjwr6';
export var authCodeErrorMessage = '_17xkjwr8';
export var authCodeWrapper = '_17xkjwr7';
export var authContent = '_17xkjwr5';
export var authInputWrapper = '_17xkjwr1';
export var authPageContainer = '_17xkjwra';
export var formHint = '_17xkjwr2';
export var hideInSmallScreen = '_17xkjwrd';
export var input = '_17xkjwrc';
export var loading = '_17xkjwr4';
export var modalHeaderWrapper = '_17xkjwr0';
export var resendButtonWrapper = '_17xkjwr9';
export var signInPageContainer = '_17xkjwrb';