import "../core/src/components/page-list/filter/index.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/filter/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71Vy3bjIAzd5yu0nC7IsfMe8jXYlmN1CHAAx+709N97wHnYidMmXXTJRY+rKyGmh1e3nosE3icABTkjxRuHUmK7nQDUDi1zKDH3HJRWGEAhaacYedw7DjkqjzbA+oC2lLrhUFFRoNpOPibTLnoao5daeeboP3I4CPuHMVGWpJBFvHUvPYdZdNgLuyPFYW1aSFcmMorGDdKu8hyWSRIwGaJUR2yW9Ay/zAaQa6nt1b3H1jOHuVaFsG8smvSpzXvUmNeGw6xLeIQy7b3eX6MSS88hHYK2Y7zpwFMBaXfMRP5vZ3WtiiuCmbYF2ltii2dFXo73vIsfeYDTkoov05/smRUF1e5czV36TUUeo6MRRUFqx2Fh2pPb7RABvNbOUxlaoTwqz8EZkSPL0Dc4GLPVeEF35vUm7OUqr60Lg2E0naBBHxeB7Dnt+kHhexXPTNsFAfBWKEeetOIgpIRkmi4doHDISDFd+34mXgWBYr4Rjnc1j173WzYsZ/NwOU88nx/X/ng/wzWHOaQgaq9jz0ixhgpfcZhthjX+hpB/vxfS7V+eKfHHGg4XzmmVRsFSSI6CXYj/hjrie3Uy4bpNMUr/LEZYVMkzMo7NLeVa3e7UrL/se2//6h9adf/QXUHzGGZst8VtyOJCCx9sY4WJTQ2v6GKPUpJx5LaTj0/naTRjsAcAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var argStyle = 'vjs73ab';
export var ellipsisTextStyle = 'vjs73ac';
export var filterContainerStyle = 'vjs73a0';
export var filterItemCloseStyle = 'vjs73a6';
export var filterItemStyle = 'vjs73a5';
export var filterTypeIconStyle = 'vjs73aa';
export var filterTypeStyle = 'vjs73a9';
export var inputStyle = 'vjs73a7';
export var menuItemStyle = 'vjs73a1';
export var menuItemTextStyle = 'vjs73a4';
export var switchStyle = 'vjs73a8';
export var variableSelectDividerStyle = 'vjs73a3';
export var variableSelectTitleStyle = 'vjs73a2';