import "../component/src/ui/notification/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/notification/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4VU0W7bMAx871dwAQYsDwrsNO06Ff2SoRhki7bZKJIhMXGyof8+SHYW2026R5E0j3c8evUrfzsWW10/wp87gMJ5jV54pWkfJDy1x+dLtCPNjYR8Egx8MighOEO6Dx9FaJR2nYSD8t+EUFVFFoegyJepSpXb2ru91aJ0xvlz7XmabDmCuFqxThVXUxHi/W51fn5PzFqlNdlaQv4Y5x/ln1JeU2iNOkmoDCZ+ylBtBTHuQh8UgZXnmHrbB6bqJEpnGS1LCK0qURTIHaKdNP+RmjdIdcMS1pteu8/BSrSMftJGpTbDAm52aU0cY9YFYKd8TVb4foY8m/FXEA71uH+eZV+fR1Of31fF3kzFLsaNsvhVkq720Q95fLsD+srEZ0NaR7kAGI8sLgk0htpAIaa6hhhFEliCdZ1XberqLItuGHCTJSQTbTaXOhUG+o0S8oc+9B89ykRhVjQ3jU5FVxW5T85sXSAmZyV4NIrpgFPfS2CvbGiVR8sXs0eOFqdQUhZYOY8D5OC5xWIKo4rgzJ4TDNmAPOg/O2myDXriz45wONjCqHKbuLhWlcQnCdkq28xma+Lahn/H5fIjCfhCu9Z5VpHf6Bv8qRUrURmn+GXBfo+L1/5Er3Jh1571B7i1jur2Oh6Wtwapx4tOMA8fPDNRpI/vlh/Ntu7n6Zl1xI2g0tlXuGD1qXTnLwsmNv9Y9z8mYbBiCffJtu9/AeaKI8+XBQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = '_1jxbkdgc';
export var actionButton = '_1jxbkdgd';
export var actionTextColor = 'var(--_1jxbkdg3)';
export var card = '_1jxbkdg6';
export var cardBorderColor = 'var(--_1jxbkdg2)';
export var cardColor = 'var(--_1jxbkdg0)';
export var cardForeground = 'var(--_1jxbkdg1)';
export var cardInner = '_1jxbkdg7';
export var closeButton = '_1jxbkdge';
export var closeIcon = '_1jxbkdgf';
export var closeIconColor = 'var(--_1jxbkdg5)';
export var headAlignWrapper = '_1jxbkdg9';
export var header = '_1jxbkdg8';
export var icon = '_1jxbkdga';
export var iconColor = 'var(--_1jxbkdg4)';
export var main = '_1jxbkdgg';
export var title = '_1jxbkdgb';